import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import normalizeWidgetInput from 'src/common/normalizeWidgetInput';
import widgetDefinition from './CognosPriceEstimator.widget';
import { RenderFn } from 'src/types/widgets';
import CognosPriceEstimator from './components/CognosPriceEstimator';
import 'carbon-components/css/carbon-components.min.css';
import { Provider } from 'react-redux';
import { store } from './lib/redux/store';
import { TranslationProvider } from '../../common/components/TranslationProvider/TranslationProvider';

export interface widgetProps {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

function refineInputTypes(input: Record<string, unknown>): widgetProps {
  //this is taking the config param and returning it for use within the widget.
  let startDate = undefined;
  let endDate = undefined;
  if (typeof input.startDate === 'string') {
    startDate = input.startDate ? new Date(input.startDate) : undefined;
  }
  if (typeof input.endDate === 'string') {
    endDate = input.endDate ? new Date(input.endDate) : undefined;
  }
  return {
    startDate,
    endDate,
  };
}

const render: RenderFn = async function (instanceId, langCode, _origin, cb) {
  const { element, locale, messages, params } = await normalizeWidgetInput(
    instanceId,
    langCode,
    widgetDefinition,
    refineInputTypes,
  );
  if (!element || !locale || !messages) {
    return;
  }
  const localeCode = element.getAttribute('data-localecode') || 'en-us';

  ReactDOM.render(
    <Provider store={store}>
      <IntlProvider locale="en" defaultLocale="en">
        <TranslationProvider messages={messages}>
          <CognosPriceEstimator
            langCode={localeCode}
            test={false}
            countryToTest={'us'}
            startDate={params.startDate}
            endDate={params.endDate}
          />
        </TranslationProvider>
      </IntlProvider>
    </Provider>,
    element,
    () => cb(element),
  );
};

export default render;
