module.exports = {
  entry: './src/apps/cognos-price-estimator/index.tsx',
  shortcode: 'cognos-price-estimator',
  title: 'Cognos Price Estimator',
  status: 'stable', // stable or deprecated
  description: 'Allows users to determine a price.',
  preview: {
    url: `${process.env.PUBLIC_URL}/storybook/iframe.html?id=widgets-cognos-price-estimator-components--default&viewMode=story`,
  },
  settingsSchema: {
    type: 'object',
    properties: {
      fields: {
        type: 'object',
        required: ['endDate', 'startDate'],
        properties: {
          startDate: {
            type: 'string',
            title: 'Discount start date. Format: MM-DD-YYYY',
            description:
              'Enter the date when the discount starts. Format MM-DD-YYYY',
            items: {
              type: 'string',
              pattern: '^(d{1,2})-(d{1,2})-(d{4})$',
            },
            examples: [['10-01-2024']],
          },
          endDate: {
            type: 'string',
            title: 'Discount end date. Format: MM-DD-YYYY',
            description:
              'Enter the date when the discount ends. Format MM-DD-YYYY',
            items: {
              type: 'string',
              pattern: '^(d{1,2})-(d{1,2})-(d{4})$',
            },
            examples: [['10-31-2024']],
          },
        },
      },
    },
  },
  useExternalPeerDependencies: ['react', 'react-dom', 'react-intl'],
  additionalCustomProperties: {
    webSegmentPaths: ['/cloud'],
    availableTranslations: [
      'de',
      'en',
      'es',
      'esla',
      'fr',
      'it',
      'ja',
      'ko',
      'pt',
      'zh-cn',
    ],
  },
};
