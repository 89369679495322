export const minLicenses = 0;
export const maxLicenses = 200;
export const defaultStandardLicenses = 5;
export const defaultPremiumLicenses = 5;
export const defaultOption = 'option1';

// UT30 is an unique code to identify each widget in the BPPR ecosystem
export const UT30PA = '30AGF';

export const externalLink = {
  baseAddonUrl:
    'https://www.ibm.com/marketplace/purchase/configuration/en/checkout?editionID=EID2RPAX&cognospromocta1Lnavtour=true',
};
